import * as React from 'react';
import { ProjectNavbar, FormConsumer, Form, SelectInput, FormConsumerState } from '../../components';
import { IFormControl, ILabelVal, FormData } from '../../interfaces';
import { getValuesForFormData } from '../../utils';
import { projectService } from '../../services';
import { projectStore } from '../../stores';

interface IState extends FormConsumerState {
  projectId: string
}

export class UserNewProject extends FormConsumer<{}, IState> {
  state: IState = {
    ...this.state,
    projectId: this.hasProjects
      ? projectStore.projects[0].id
      : '',
  };

  formFields: IFormControl[] = [
    {
      name: 'type',
      icon: 'chevron-down',
      label: 'Type',
      options: this.projectOptions,
      type: 'select',
    },
    {
      name: 'name',
      label: 'Name',
      rules: ['required', 'min:2', 'max:255'],
      type: 'text',
    },
  ];

  emitter(formData: FormData) {
    this.setState({ formData });
  }

  onSubmit() {
    const { projectId } = this.state
    const values = {
      ...getValuesForFormData(this.state.formData),
      ...(this.copyOptionChosen && projectId
        ? { projectId }
        : {}
      )
    };
    projectService
      .create(values)
      .then(project => projectStore.setProject(project))
      .catch(err => console.log(err));
  }

  get copyOptionChosen(): boolean {
    const { formData } = this.state;
    return formData.type && formData.type.value === 'copy' && this.hasProjects;
  }

  get hasProjects(): boolean {
    return projectStore.projects.length > 0;
  }

  get projectOptions(): ILabelVal[] {
    return [
      {
        label: 'From template',
        value: 'template',
      },
      {
        label: 'From scratch',
        value: 'scratch',
      },
      ...(projectStore.projects.length > 0
        ? [{
          label: 'Copy another project',
          value: 'copy'
        }] : []
      ),
    ];
  }

  render() {
    return (
      <main className="flex-down align-center justify-start h-100vh">
        <ProjectNavbar
          backButtonPath="/projects"
          title="New project"
        />
        <hr />
        <div className="flex-down align-start justify-start full-width p-1p5 w-600">
          <Form
            fields={this.formFields}
            buttonText="Create"
            submitted={this.state.submitted}
            onSubmit={(formData: FormData) => this.retrieveFormData(formData)}
            emitter={(formData: FormData) => this.emitter(formData)}
          >
            {this.copyOptionChosen && (
              <SelectInput
                label="Project to copy"
                icon="chevron-down"
                value={this.state.projectId}
                options={projectStore.projects.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
                setValue={(key: string, projectId: string) => this.setState({ projectId })}
              />
            )}
          </Form>
        </div>
      </main>
    );
  }
}
