import { Project } from '../classes';
import { projectService } from '../services';
import { routerStore } from '../stores';

class ProjectController {
  async delete(id: string) {
    await projectService
      .delete(id)
      .catch(err => console.log(err));

    routerStore.redirect('/projects');
  }

  async deleteProcesses(project: Project, processes: string[]) {
    await projectService.deleteProcesses(project.id, processes);
    await projectService.update(project.formattedForAPI);
  }

  async spreadsheet(project: Project) {
    await projectService.spreadsheet(project);
  }
}

export const projectController = new ProjectController();
