import { ProjectActions } from '../interfaces';
import { Process, Model } from '../classes';

export const getIdentifierForAction = (actionType: ProjectActions, process: Process|null): string => {
  if (process) {
    const { identifier } = process;
    switch (actionType) {
      case ProjectActions.AFTER:
        return getNextIdentifier(process);

      case ProjectActions.BEFORE:
      case ProjectActions.UPDATE:
        return identifier;

      case ProjectActions.CHILD:
        return process.lastChild
          ? getNextIdentifier(process.lastChild)
          : `${identifier}.1`;
    }
  }

  return '1';
}

export const getPrevProcess = (prev_id: string|null, model: Model): (() => Process|null) => {
  if (prev_id && prev_id in model.processes) {
    return () => model.processes[prev_id];
  }
  return () => null;
}

export const getNextIdentifier = (process: Process): string => {
  const levelArray: string[] = process.identifier.split('.');
  const lastLevel: string|undefined = levelArray.pop();

  if (levelArray.length > 0) {
    return `${levelArray.join('.')}.${parseInt((lastLevel as string), 10) + 1}`;
  }

  return `${parseInt(process.identifier, 10) + 1}`;
}

export const getLeafProcessForTree = (process: Process, model: Model): Process|null => {
  if (process.next_id) {
    let currentProcess = model.processes[process.next_id];
    // Make sure we're looking at its children
    if (currentProcess.parent_id === process.id) {
      while (currentProcess.next_id) {
        const nextProcess = model.processes[currentProcess.next_id];
        if (nextProcess.identifier.split('.').length <= process.identifier.split('.').length) {
          break;
        }
        currentProcess = nextProcess;
      }
      return currentProcess;
    }
  }
  return null;
}
