import * as mimeTypes from 'mime-types';

export const formatApiErrors = (errorObject: any): Array<string> => {
  const errors: Array<string> = [];
  for (const key in errorObject) {
    const field: any = errorObject[key];
    switch (typeof field) {
      case 'string':
        errors.push(field);
        break;

      case 'object':
        for (const childKey in field) {
          const array: Array<string> = field[childKey];
          for (const index of array) {
            errors.push(index);
          }
        }
        break;
    }
  }
  return errors;
}

export const downloadFileBlob = (blob: Blob, projectName: string): void => {
    // Create a downloadable object and trigger download
    const filename = `${projectName}-export-${new Date().getTime()}.${mimeTypes.extension(blob.type)}`;
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
}
