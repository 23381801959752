import { createBrowserHistory, History } from 'history';

interface IConfig {
  appName: string;
  history: History;
  loginPath: string;
  logoutPath: string;
  sessionKey: string;
}

const sessionKey: string = 'scor_session';

export const CONFIG: IConfig = {
  appName: 'SCOR',
  history: createBrowserHistory(),
  loginPath: '/projects',
  logoutPath: '/login',
  sessionKey,
};
