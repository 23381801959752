import { action, computed, observable } from 'mobx';
import { v4 as uuid } from 'uuid';
import { IProcess, IProcessData, IProcessCosts, IProcessTime } from '../interfaces';
import { Model } from './Model';

export interface KeyProcess {
  [key: string]: Process;
}

export class Process {
  id: string;
  @observable model_id: string|null;
  @observable parent_id: string|null;
  @observable prev_id: string|null;
  @observable next_id: string|null;
  @observable prev_sibling_id: string|null;
  @observable next_sibling_id: string|null;
  @observable name: string;
  @observable description: string;
  @observable notes: string;
  @observable costs: IProcessCosts[];
  @observable data: IProcessData[];
  @observable time: IProcessTime[];
  model: (() => Model);

  constructor(process: IProcess, model: Model) {
    this.id = process.id;
    this.model_id = process.model_id;
    this.parent_id = process.parent_id;
    this.prev_id = process.prev_id;
    this.next_id = process.next_id;
    this.prev_sibling_id = process.prev_sibling_id;
    this.next_sibling_id = process.next_sibling_id;
    this.name = process.name;
    this.description = process.description;
    this.notes = process.notes;
    this.costs = JSON.parse(process.costs);
    this.data = JSON.parse(process.data);
    this.time = JSON.parse(process.time);
    this.model = () => model;
  }

  /**
   * Getters.
   *
   * @return {var}
   */
  @computed
  get hasPreviousProcess(): boolean {
    return this.prev_id !== null;
  }

  @computed
  get hasNextProcess(): boolean {
    return this.next_id !== null;
  }

  @computed
  get displayName(): string {
    return `${this.identifier} - ${this.name}`;
  }

  @computed
  get isDeletable(): boolean {
    return this.hasPreviousProcess || this.leafNode.hasNextProcess;
  }

  get leafNode(): Process {
    let leaf: Process = this;
    while (true) {
      if (!leaf.hasNextProcess) { break; }
      const nextProcess: Process = this.model().processes[leaf.next_id as string];
      if (nextProcess.identifier.startsWith(this.identifier)) {
        leaf = nextProcess;
      } else {
        break;
      }
    }
    return leaf;
  }

  @computed
  get identifier(): string {
    if (this.prevProcess) {
      if (this.parent_id === this.prev_id) {
        return `${this.prevProcess.identifier}.1`;
      } else if (this.parent_id !== null && this.prevProcess.parent_id === this.parent_id) {
        // @TODO Create function for this, as its used everywhere
        const array: string[] = this.prevProcess.identifier.split('.');
        const lastElement = (array.pop() as string);
        return `${array.join('.')}.${parseInt(lastElement, 10) + 1}`;
      } else if (this.prev_sibling_id) {
        // @TODO Same as above
        const prevSibling = this.model().processes[this.prev_sibling_id];
        const array: string[] = prevSibling.identifier.split('.');
        const lastElement = (array.pop() as string);
        if (lastElement && array.length > 0) {
          return `${array.join('.')}.${parseInt(lastElement, 10) + 1}`;
        }
        return `${parseInt(prevSibling.identifier, 10) + 1}`;
      }
    }

    // Process must be root process
    return '1';
  }

  @computed
  get prevProcess(): Process|null {
    const model = this.model();
    if (this.prev_id && model && this.prev_id in model.processes) {
      return model.processes[this.prev_id];
    }
    return null;
  }

  @computed
  get lastChild(): Process|null {
    if (!this.next_id) {
      return null;
    }

    const model = this.model();
    let process = model.processes[this.next_id];

    if (process.parent_id !== this.id) {
      return null;
    }

    while (process.next_id) {
      let nextProcess = model.processes[process.next_id]
      if (nextProcess.parent_id === this.id) {
        process = nextProcess;
      } else {
        break;
      }
    }

    return process;
  }

  /**
   * Delete all children and return last node in tree.
   *
   * @return {Process}
   */
  @action
  deleteChildren(): string[] {
    if (!this.lastChild) { return []; }

    // Collect ids of all child processes to delete
    const processesToDelete: string[] = [];
    let process: Process = this.model().processes[this.next_id as string];
    while (process.identifier.startsWith(this.identifier)) {
      processesToDelete.push(process.id);
      if (!process.hasNextProcess) { break; }
      process = this.model().processes[process.next_id as string];
    }

    // Delete all child processes
    processesToDelete.forEach(id => {
      this.model().processes[id].delete();
    });

    return processesToDelete;
  }

  /**
   * Delete process.
   *
   * @return {Process}
   */
  @action
  delete(): Process {
    if (this.hasPreviousProcess) {
      const previousProcess = (this.prevProcess as Process);
      previousProcess.next_id = this.next_id;
    }
    if (this.hasNextProcess) {
      const nextProcess = this.model().processes[(this.next_id as string)];
      nextProcess.prev_id = this.prev_id;
    }

    // Transfer next- and prev_sibling_id to the next and prev sibling process
    if (this.prev_sibling_id) {
      this.model().processes[this.prev_sibling_id].next_sibling_id = this.next_sibling_id;
    }
    if (this.next_sibling_id) {
      this.model().processes[this.next_sibling_id].prev_sibling_id = this.prev_sibling_id;
    }

    delete this.model().processes[this.id];

    return this;
  }
}

export const newProcess = (model: Model, name: string, description: string, notes: string, costs: IProcessCosts[] = [], data: IProcessData[] = [], time: IProcessTime[] = [], parent_id: string|null = null, prev_id: string|null = null, next_id: string|null = null, prev_sibling_id: string|null = null, next_sibling_id: string|null = null): Process => {
  const process: IProcess = {
    id: uuid(),
    model_id: model.id,
    parent_id,
    next_id,
    prev_id,
    prev_sibling_id,
    next_sibling_id,
    name,
    description,
    notes,
    costs: JSON.stringify(costs),
    data: JSON.stringify(data),
    time: JSON.stringify(time),
  };

  return new Process(process, model);
}
