import { IProcessCosts } from "../interfaces";

export const getWholeNumber = (num: number) => parseInt(num.toString(), 10);

export const getDecimalPoint = (num: number) => {
  const float = num.toFixed(2);
  const decimalIndex = float.indexOf('.');
  return float.substr(decimalIndex);
}

export const numberWithCommas = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const handleNumberInput = (event: React.ChangeEvent<HTMLInputElement>, max?: number) => {
  let value: string = event.target.value;
  const lastChar = value.charAt(value.length - 1);
  const prevValue = value.substr(0, value.length - 1);

  // Inhibit other chars than number
  if (lastChar === '.' && (value.match(/\./g) || []).length === 1) {}
  else if (
    value.length > 0 && !(parseInt(lastChar, 10) >= 0)
    || max && !(parseFloat(value) <= max)
  ) { return prevValue; }

  return value;
}

export const calculateCostsSum = (costs: IProcessCosts[]) => {
  let sum: number = 0;
  costs.forEach(el => sum += parseFloat(el.cost));
  return sum;
}

export const npv = (numbers: number[], rate: number, initialCost: number = 0) => {
  return numbers.reduce((accumulator, currentValue, index) => accumulator + currentValue / Math.pow(rate / 100 + 1, index + 1), initialCost);
}

export const format = (num: number | string, precision: number = 2) => {
  const numAsNumber = typeof num === 'string'
    ? parseFloat(num)
    : num;
  const numWithPrecision = numAsNumber.toFixed(precision);
  const numArray = numWithPrecision.split('.');
  return `${parseInt(numArray[0]).toLocaleString()}.${numArray[1]}`;
}
