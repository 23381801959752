import * as React from 'react';
import { observer } from 'mobx-react';
import { projectController as controller } from '../../controllers';
import { SaveStates } from '../../interfaces';
import { Icon } from '../shared';
import { projectStore, routerStore, autosaveStore, roiStore } from '../../stores';
import { ContentTrigger } from './content-trigger.component';

interface IProps {
  backButtonPath: string;
  showProjectModifiers?: boolean;
  title?: string;
}

@observer
export class ProjectNavbar extends React.Component<IProps> {
  get savingStateIndicator(): JSX.Element|null {
    switch (autosaveStore.saveState) {
      case SaveStates.SAVING:
        return <Icon className="animate--rotate hw-28" name="loading-animate" />;

      case SaveStates.SAVED:
        return <Icon className="hw-30" name="checked" />;

      default:
        return null;
    }
  }

  get title(): string {
    const { project } = projectStore;
    const { title } = this.props;

    return title
      ? title
      : project
        ? project.name
        : '';
  }

  render() {
    const { project } = projectStore;
    const { backButtonPath, showProjectModifiers } = this.props;

    return (
      <div className="navbar flex align-center justify-center full-width p-1p5">
        <button
          className="left-wrapper flex align-center justify-center"
          onClick={() => routerStore.redirect(backButtonPath)}
        >
          <Icon
            className="hw-24"
            name="chevron-left"
          />
          <p>Back</p>
        </button>

        <h4>{this.title}</h4>

        {(project && showProjectModifiers) && (
            <div className="right-wrapper flex align-center justify-start">
              {/* {this.savingStateIndicator} */} {/* @TODO Re-enable to show save state */}
              <div className="dropdown-wrapper ml-1">
                <ContentTrigger
                  type="dropdown"
                  buttonClassName="dropdown-trigger button--medium flex align-center justify-between"
                  buttonContent={
                    <>
                      <p>Project action</p>
                      <Icon
                        className="hw-22 ml-0p25"
                        name="chevron-down"
                      />
                    </>
                  }
                  content={
                    <div className="dropdown dropdown--medium w-210">
                      <button onClick={() => controller.spreadsheet(project)}>
                        Download spreadsheet
                      </button>
                      <button onClick={() => {
                        roiStore.hydrate();
                        routerStore.redirect('/roi')
                      }}>
                        Calculate ROI
                      </button>
                      <button onClick={() => autosaveStore.save()}>
                        Save
                      </button>
                      <button onClick={() => {
                        if (confirm('Are you sure you want to delete this project?')) {
                          controller.delete(project.id)
                        }
                      }}>
                        Delete
                      </button>
                    </div>
                  }
                />
              </div>
            </div>
        )}
      </div>
    );
  }
}
