import * as React from 'react';
import { IFormControl, IFormFieldProps, FormData, IFormError, IFormRowCollection, IFormState } from '../../interfaces';
import * as Validators from '../../validators';
import { CheckboxInput } from './checkbox-input.component';
import { TextInput } from './text-input.component';
import { TextAreaInput } from './textarea-input.component';
import { SelectInput } from './select-input.component';
import { Button } from '../shared';

interface IProps {
  buttonText: string;
  children?: React.ReactNode;
  className?: string;
  fields: IFormControl[];
  submitted: boolean;
  onSubmit: (formData: FormData) => void;
  emitter?: (formData: FormData) => void;
}

// @IMPROVEMENT I'd love to refactor this so that it doesn't
// need to grab the fields from the state, and then the actual
// field from fields[key] all the time...

export class Form extends React.Component<IProps, IFormState> {
  state: IFormState = {
    fields: this.getInitialStateForFields(this.props.fields)
  };

  private validators: Validators.IValidator = {
    'debtAmount': (field: IFormControl) => Validators.debtAmount(field),
    'email':      (field: IFormControl) => Validators.email(field),
    // 'exactly':    (field: IFormControl) => Validators.exactly(field),
    'landline':   (field: IFormControl) => Validators.landline(field),
    // 'matchField': (field: IFormControl) => Validators.matchField(field),
    'max':        (field: IFormControl) => Validators.max(field),
    'min':        (field: IFormControl) => Validators.min(field),
    'mobile':     (field: IFormControl) => Validators.mobile(field),
    'numeric':    (field: IFormControl) => Validators.numeric(field),
    'phone':      (field: IFormControl) => Validators.phone(field),
    'required':   (field: IFormControl) => Validators.required(field),
    'smsName':    (field: IFormControl) => Validators.smsName(field),
  };

  componentWillUpdate(nextProps: IProps) {
    if (nextProps.fields !== this.props.fields) {
      const fields = this.getInitialStateForFields(nextProps.fields);
      this.setState({ fields });
    }
  }

  /**
   * For the given fields, initialise the form data.
   *
   * @param fields IFormControl[]
   * @return {FormData}
   */
  getInitialStateForFields(fields: IFormControl[]): FormData {
    const state: FormData = {};

    for (const key in fields) {
      const field = fields[key];

      // Set initial value
      switch (field.type) {
        case 'checkbox':
          field.checked = field.defaultValue || false;
          break;

        case 'datepicker':
          field.value = new Date();
          break;

        case 'select':
          field.value = field.defaultValue || (field.options ? field.options[0].value : null);
          break;

        case 'amount':
        case 'email':
        case 'expiry':
        case 'password':
        case 'tel':
        case 'text':
        case 'textarea':
          field.value = field.defaultValue || '';
          break;

        default:
          // @IMPROVEMENT Throw 'wrong type' warning
          break;
      }

      // Instantiate errors property if field has validation rules
      if (field.rules !== undefined && field.rules.length > 0) {
        field.errors = field.rules.map(rule => {
          return {
            name: rule,
            valid: true,
          };
        })
      }

      // Set default values
      field.touched = false;
      field.focused = false;

      // Give each field a reference to the parent state
      field.parent = () => this.state.fields;

      // Put field in state
      state[field.name] = field;
    }

    return state;
  }

  emit(): void {
    const { emitter } = this.props;
    emitter && emitter(this.state.fields);
  }

  /**
   * Validate the field and retrieve the errors object.
   *
   * @param field IFormControl
   * @return {IFormError[] | undefined}
   */
  getErrorsForField(field: IFormControl): IFormError[]|undefined {
    if (field.rules !== undefined && field.rules.length > 0) {
      return field.rules.map(rule => {
        // Check for dynamic rule (e.g. max:255)
        const colonIndex: number = rule.indexOf(':');
        if (colonIndex !== -1) {
          const ruleName: string = rule.substr(0, colonIndex);
          return {
            name: ruleName,
            valid: this.validators[ruleName](field),
          };
        }

        return {
          name: rule,
          valid: this.validators.hasOwnProperty(rule)
            ? this.validators[rule](field)
            : true,
        };
      });
    }
    return field.errors;
  }

  /**
   * onBlur handler attached to the form inputs.
   *
   * @param key string
   * @return {void}
   */
  onBlur(key: string): void {
    const { fields } = this.state
    const field = fields[key];

    if (!field.touched) {
      field.touched = true;
      field.errors = this.getErrorsForField(field);
    }

    field.focused = false;
    fields[key] = field;

    this.setState({ fields });
  }

  /**
   * Directly set value of form field.
   *
   * @param key string
   * @param val any
   * @return {void}
   */
  setValue(key: string, val: any): void {
    const { fields } = this.state;
    const field = fields[key];
    // Set value and update errors
    field.value = val;
    field.touched = true;
    field.errors = this.getErrorsForField(field);
    fields[key] = field;
    this.setState({ fields }, () => this.emit());
  }

  /**
   * onChange handler attached to the form inputs.
   *
   * @param formControl IFormControl
   * @param event React.FormEvent<HTMLInputElement>
   * @return {void}
   */
  onChange(formControl: IFormControl, event: React.FormEvent<HTMLInputElement>): void {
    const { fields } = this.state;
    const field: IFormControl = fields[formControl.name];

    // Set value
    switch (field.type) {
      case 'checkbox':
        field.checked = !field.checked;
        break;

      case 'amount':
      case 'email':
      case 'expiry':
      case 'password':
      case 'tel':
      case 'text':
      case 'textarea':
      default:
        field.value = event.currentTarget.value;
        break;
    }

    // Set touched
    field.touched = true;

    // Update errors
    field.errors = this.getErrorsForField(field);

    fields[field.name] = field;

    this.setState({ fields });
  }

  /**
   * onBlur handler attached to the form inputs.
   *
   * @param key string
   * @return {void}
   */
  onFocus(key: string): void {
    const { fields } = this.state;
    const field = fields[key];
    field.focused = true;
    fields[key] = field;
    this.setState({ fields });
  }

  /**
   * Form onSubmit.
   * Send data to form consumer.
   *
   * @param e React.FormEvent
   * @return {void}
   */
  onSubmit(e: React.FormEvent): void {
    e.preventDefault();
    if (this.valid) {
      this.props.onSubmit(this.state.fields);
    }
  }

  /**
   * For the given form control, return its input field.
   *
   * @param field IFormControl
   * @return {JSX.Element}
   */
  getElementForField(field: IFormControl): JSX.Element {
    const props: IFormFieldProps = {
      ...field,
      key: field.name,
      onBlur: () => this.onBlur(field.name),
      onFocus: () => this.onFocus(field.name),
      onChange: (input: React.FormEvent<HTMLInputElement>) => this.onChange(field, input),
      setValue: (key: string, val: any) => this.setValue(key, val),
    };

    switch (field.type) {
      case 'checkbox':
        return <CheckboxInput {...props} />;

      case 'select':
        return <SelectInput {...props} />;

      case 'textarea':
        return <TextAreaInput {...props} />;

      default:
        return <TextInput {...props} />;
    }
  }

  /**
   * Check whether form is valid,
   * and validate the form fields in the process.
   *
   * @return {boolean}
   */
  get valid(): boolean {
    const { fields } = this.state

    // Get errors for all fields
    for (const key in fields) {
      const field = fields[key];
      field.errors = this.getErrorsForField(field);
      field.touched = true;
      fields[key] = field;
    }

    this.setState({ fields });

    // Check for errors
    for (const key in fields) {
      const field = fields[key];
      if (field.errors !== undefined && field.errors.length > 0) {
        for (const error of field.errors) {
          if (!error.valid) {
            return false;
          }
        }
      }
    }

    return true;
  }

  /**
   * Render form fields.
   *
   * @return {JSX.Element[]}
   */
  get renderFormFields(): JSX.Element[] {
    const { fields } = this.state;
    const formFields: JSX.Element[] = [];
    const rows: IFormRowCollection = {};

    for (const key in fields) {
      const field: IFormControl = fields[key];

      // If field is part of a row, push to row
      if (field.row !== undefined) {
        const rowName: string = field.row.name;
        const row = rows[rowName] !== undefined
          ? rows[rowName].concat(field)
          : [field];

        rows[rowName] = row;

        // If a row is complete, push the row
        if (row.length === field.row.length) {
          formFields.push(
            <div key={rowName} className="form-row flex align-start justify-center">
              {row.map(formField => <div key={`${rowName}_${formField.name}`} className="flex-down full-width">{this.getElementForField(formField)}</div>)}
            </div>
          );
        }

        continue;
      }

      formFields.push(this.getElementForField(field));
    }

    return formFields;
  }

  render() {
    return (
      <form
        className={`form ${this.props.className ? this.props.className : ''}`}
        onSubmit={e => this.onSubmit(e)}
      >
        {this.renderFormFields}
        {this.props.children && this.props.children}
        <Button
          className="button mt-1"
          disabled={this.props.submitted}
          disabledText="Processing..."
          children={this.props.buttonText}
        />
      </form>
    );
  }
}
