import { action, computed, observable, observe, reaction } from 'mobx';
import { npv } from '../utils';
import { projectStore } from './project.store';
import { IRoiPersist } from '../interfaces';
import { projectService } from '../services';

interface IMetrics {
  as_is: IModelMetrics;
  to_be: IModelMetrics;
}

interface IModelMetrics {
  costInDollars: number;
  timeInSeconds: number;
}

interface ICostTime {
  cost: number;
  time: number;
}

interface IDifferencePerUnit {
  benefits: ICostTime;
  costs: ICostTime;
}

class ROIStore {
  @observable salary: string = '';
  @observable interestRate: string = '';
  @observable numberOfUnits: string = '';
  @observable weight: string = '';
  @observable increasePerKilo: string = '';
  @observable labourCostSavings: string = '';
  @observable years: string = '';
  @observable tempInputField: string = '';
  @observable inputFields: string[] = [];
  @observable inputMap: Map<string, string> = new Map();
  timeoutId: number = 0;

  constructor() {
    observe(this, () => this.persist());

    reaction(
      () => Array.from(this.inputMap.values()).map(value => value),
      () => this.persist()
    );
  }

  @action.bound
  clearAll(): void {
    this.salary = '';;
    this.interestRate = '';
    this.numberOfUnits = '';
    this.weight = '';
    this.increasePerKilo = '';
    this.labourCostSavings = '';
    this.years = '';
    this.tempInputField = '';
    this.inputFields = [];
    this.inputMap.clear();
  }

  @action
  hydrate(): void {
    const { project } = projectStore
    if (project) {
      const { roi } = project;
      if (roi) {
        this.salary = roi.salary;
        this.interestRate = roi.interest_rate;
        this.numberOfUnits = roi.number_of_units;
        this.weight = roi.weight;
        this.increasePerKilo = roi.increasePerKilo;
        this.labourCostSavings = roi.labourCostSavings;
        this.years = roi.years;
        this.inputFields = roi.input_fields;
        roi.input_map.forEach(({ key, value }) => this.inputMap.set(key, value));
      }
    }
  }

  persist(): void {
    window.clearTimeout(this.timeoutId);
    this.timeoutId = window.setTimeout(() => {
      if (projectStore.project) {
        projectService.setRoi(projectStore.project, this.toApiFormat);
        projectStore.project.roi = this.toApiFormat;
      }
    }, 200);
  }

  createInputMapKey(year: number, inputName: string): string {
    return `${year}_${inputName}`;
  }

  getInput(year: number, inputName: string): string {
    return this.inputMap.get(this.createInputMapKey(year, inputName)) || '';
  }

  @action
  setInput(year: number, inputName: string, value: string): void {
    this.inputMap.set(this.createInputMapKey(year, inputName), value);
  }

  @action
  addInputField(): void {
    this.inputFields.push(this.tempInputField);
    this.tempInputField = '';
  }

  calculateNpv(numbers: number[]): number {
    const interestRate = parseFloat(this.interestRate || '0');
    return npv(numbers, interestRate) * (1 + (interestRate / 100));
  }

  @computed
  get toApiFormat(): IRoiPersist {
    const { project } = projectStore;

    return {
      project_id: project
        ? project.id
        : '',
      salary: this.salary,
      interest_rate: this.interestRate,
      number_of_units: this.numberOfUnits,
      weight: this.weight,
      increasePerKilo: this.increasePerKilo,
      labourCostSavings: this.labourCostSavings,
      years: this.years,
      input_fields: this.inputFields,
      input_map: Array
        .from(this.inputMap.entries())
        .map(([key, value]) => ({ key, value }))
        .filter(e => e),
    }
  }

  @computed
  get stepOneCompleted(): boolean {
    return [
      this.salary,
      this.interestRate,
      this.numberOfUnits,
      this.weight,
      this.years,
    ].every(e => !!e);
  }

  @computed
  get yearsArray(): number[] {
    const value = parseInt(this.years, 10) || 0;
    return Array.from(Array(value).keys()).map(year => year + 1);
  }

  /** Calculations */
  @computed
  get metrics(): IMetrics {
    const newModelMetrics = (): IModelMetrics => {
      return {
        costInDollars: 0,
        timeInSeconds: 0,
      };
    };

    const metrics: IMetrics = {
      as_is: newModelMetrics(),
      to_be: newModelMetrics(),
    };

    if (projectStore.project) {
      const { as_is, to_be } = projectStore.project;
      [as_is, to_be].forEach(model => {
        Object.values(model.processes).forEach(process => {
          // Sum costs in dollars
          metrics[model.type].costInDollars += process.costs.reduce((prev, curr) => (
            prev + parseFloat(curr.cost)
          ), 0) || 0;

          // Sum time in seconds
          metrics[model.type].timeInSeconds += process.time.reduce((prev, curr) => {
            let value = parseFloat(curr.value);
            switch (curr.unit) {
              case 'minutes':
                value *= 60;
                break;

              case 'hours':
                value *= 3600;
                break;

              case 'days':
                value *= 86400;
                break;
            }
            return prev + value;
          }, 0) || 0;
        });
      });
    }

    return metrics;
  }

  @computed
  get fixedCostsPerYear(): number[] {
    return this.yearsArray.map(year => (
      this.inputFields.reduce((accumalator, field) => {
        const input = this.getInput(year, field) || '0';
        return accumalator + parseFloat(input);
      }, 0)
    ));
  }

  @computed
  get differencesPerUnit(): IDifferencePerUnit {
    const { as_is, to_be } = this.metrics;
    const cost = as_is.costInDollars - to_be.costInDollars;
    const time = as_is.timeInSeconds - to_be.timeInSeconds;
    return {
      benefits: {
        cost: cost > 0 ? cost : 0,
        time: time > 0 ? time : 0,
      },
      costs: {
        cost: cost < 0 ? Math.abs(cost) : 0,
        time: time < 0 ? Math.abs(time) : 0,
      },
    };
  }

  /**
   * Variable cost.
   *
   * @return {number}
   */
  @computed
  get perHeadAdditionalCost(): number {
    return this.differencesPerUnit.costs.cost * parseFloat(this.numberOfUnits || '0');
  }

  @computed
  get perHeadAdditionalTimeCost(): number {
    return (this.differencesPerUnit.costs.time / 3600) * parseFloat(this.salary || '0') * parseFloat(this.numberOfUnits || '0');
  }

  @computed
  get totalVariableAdditionalCost(): number {
    return this.perHeadAdditionalCost + this.perHeadAdditionalTimeCost;
  }

  /**
   * Benefits.
   *
   * @return {number}
   */
  @computed
  get expectedPriceIncreases(): number {
    return parseFloat(this.numberOfUnits || '0') * parseFloat(this.weight || '0') * parseFloat(this.increasePerKilo || '0');
  }

  @computed
  get expectedLabourCostSavings(): number {
    return parseFloat(this.labourCostSavings || '0');
  }

  @computed
  get perHeadCostSavings(): number {
    return this.differencesPerUnit.benefits.cost * parseFloat(this.numberOfUnits || '0');
  }

  @computed
  get perHeadTimeCostSavings(): number {
    return (this.differencesPerUnit.benefits.time / 3600) * parseFloat(this.salary || '0') * parseFloat(this.numberOfUnits || '0');
  }

  @computed
  get totalBenefits(): number {
    return this.expectedPriceIncreases
      + this.expectedLabourCostSavings
      + this.perHeadCostSavings
      + this.perHeadTimeCostSavings;
  }

  /**
   * Delta.
   *
   * @return {number}
   */
  @computed
  get netInflowPerYear(): number[] {
    return this.fixedCostsPerYear.map(cost => this.totalBenefits - this.totalVariableAdditionalCost - cost);
  }

  @computed
  get netInflowPerKiloPerYear(): number[] {
    return this.netInflowPerYear.map(inflow => inflow / (parseFloat(this.numberOfUnits || '0') * parseFloat(this.weight || '0')));
  }

  @computed
  get variableCostsPerYear(): number[] {
    return Array
      .from({ length: this.yearsArray.length })
      .map(() => this.totalVariableAdditionalCost);
  }

  @computed
  get benefitsPerYear(): number[] {
    return Array
      .from({ length: this.yearsArray.length })
      .map(() => this.totalBenefits);
  }

  @computed
  get ratioBenefitsToCosts(): number | null {
    const npvBenefits = this.calculateNpv(this.benefitsPerYear);
    const npvFixedCosts = this.calculateNpv(this.fixedCostsPerYear);
    const npvVariableCosts = this.calculateNpv(this.variableCostsPerYear);
    const npvTotalCost = npvFixedCosts + npvVariableCosts;
    return npvBenefits && npvTotalCost
      ? npvBenefits / npvTotalCost
      : null;
  }
}

export const roiStore = new ROIStore();
