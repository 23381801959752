import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormConsumer, Form } from '../../components';
import { IFormControl, FormData, IUserAPIResponse } from '../../interfaces';
import { authService, session } from '../../services';
import { routerStore } from '../../stores';
import { getValuesForFormData, formatApiErrors } from '../../utils';

export class Login extends FormConsumer {
  formFields: IFormControl[] = [
    {
      name: 'email',
      label: 'Email',
      rules: ['required', 'email', 'max:255'],
      type: 'email',
    },
    {
      name: 'password',
      label: 'Password',
      rules: ['required', 'min:6', 'max:255'],
      type: 'password',
    },
  ];

  onSubmit() {
    const instance = getValuesForFormData(this.state.formData);
    authService
      .login(instance)
      .then((response: IUserAPIResponse) => {
        session.populate({
          access_token: response.access_token,
          ...response.profile,
        });
        routerStore.login();
      })
      .catch(err => {
        this.apiResponse = formatApiErrors(err);
        this.setState({ submitted: false });
      });
  }

  render() {
    return (
      <main className="flex-down align-center justify-center h-100vh">
        <div className="flex-down align-start justify-start w-400 p-1">
          <div className="flex align-end justify-between full-width">
            <h2>Log in</h2>
            <Link className="btn-link" to="/register">Register</Link>
          </div>
          <Form
            className="mt-1"
            fields={this.formFields}
            buttonText="Log in"
            submitted={this.state.submitted}
            onSubmit={(formData: FormData) => this.retrieveFormData(formData)}
          />
          {this.apiResponseElement}
        </div>
      </main>
    );
  }
}
