import * as React from 'react';
import { Project } from '../../classes';
import { ModelWrapper, ProjectNavbar, DualNavigation } from '../../components';
import { projectStore, routerStore, autosaveStore } from '../../stores';

export class UserProject extends React.Component {
  componentDidMount() {
    if (this.project) {
      autosaveStore.initiate(this.project);
    } else {
      routerStore.redirect('/projects');
    }
  }

  get project(): Project|undefined {
    return projectStore.project;
  }

  render() {
    return (
      <main className="flex-down align-stretch justify-start h-100vh">
        <ProjectNavbar
          backButtonPath="/projects"
          showProjectModifiers
        />
        <hr />
        {this.project && (
          <div className="project-wrapper flex flex-1 align-stretch justify-center full-width">
            <DualNavigation project={this.project} />
            <ModelWrapper
              name="As Is"
              model={this.project.as_is}
            />
            <span className="vr" />
            <ModelWrapper
              name="To Be"
              model={this.project.to_be}
            />
          </div>
        )}
      </main>
    );
  }
}
