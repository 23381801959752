import * as React from 'react';
import { observer } from 'mobx-react';
import { ProjectNavbar, TextInput, Gate, Icon, Button } from '../../components';
import { roiStore } from '../../stores';
import { format, handleNumberInput } from '../../utils';

@observer
export class UserROI extends React.Component {
  render() {
    const {
      benefits,
      costs,
    } = roiStore.differencesPerUnit;

    return (
      <main className="flex-down align-center justify-start h-100vh mb-3">
        <ProjectNavbar backButtonPath="/project" />
        <hr />

        <div className="flex-down align-start justify-start full-width p-1p5 w-600">
          <div className="flex align-start justify-between full-width">
            <h2>Fill out parameters</h2>
            <Button
              className="button"
              onClick={roiStore.clearAll}
            >
              Clear all
            </Button>
          </div>

          <h4 className="mt-2">Cost of Labour (Hourly Salary)</h4>
          <TextInput
            className="mt-0p5"
            label="Cost of Labour (Hourly Salary)"
            placeholder="$ per hour"
            value={roiStore.salary}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => roiStore.salary = handleNumberInput(e)}
          />

          <h4 className="mt-2">Cost of Capital</h4>
          <TextInput
            className="mt-0p5"
            label="Cost of Capital"
            placeholder="% per year"
            value={roiStore.interestRate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => roiStore.interestRate = handleNumberInput(e)}
          />

          <h4 className="mt-2">Number of Animals Processed Anually</h4>
          <TextInput
            className="mt-0p5"
            label="Number of Animals Processed Anually"
            value={roiStore.numberOfUnits}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => roiStore.numberOfUnits = handleNumberInput(e)}
          />

          <h4 className="mt-2">Carcase Weight</h4>
          <TextInput
            className="mt-0p5"
            label="Carcase Weight"
            placeholder="kg"
            value={roiStore.weight}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => roiStore.weight = handleNumberInput(e)}
          />

          <h4 className="mt-2">Number of Years</h4>
          <TextInput
            className="mt-0p5"
            label="Number of Years"
            value={roiStore.years}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => roiStore.years = handleNumberInput(e)}
          />

          <h3 className="mt-3">Expected system wide benefits</h3>

          <h4 className="mt-2">Sell Price Increase Per Kilogram</h4>
          <TextInput
            className="mt-0p5"
            label="Sell Price Increase Per Kilogram"
            placeholder="$ per kg"
            value={roiStore.increasePerKilo}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => roiStore.increasePerKilo = handleNumberInput(e)}
          />

          <h4 className="mt-2">Labour Cost Savings Per Year</h4>
          <TextInput
            className="mt-0p5"
            label="Labour Cost Savings Per Year"
            placeholder="$ per year"
            value={roiStore.labourCostSavings}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => roiStore.labourCostSavings = handleNumberInput(e)}
          />

          <Gate condition={roiStore.stepOneCompleted}>
            <h2 className="mt-4">Add yearly costs</h2>
            <h4 className="mt-2">Add cost rows</h4>
            <div className="flex align-stretch justify-center full-width remove-right-borders mt-0p5">
              <TextInput
                label="Cost row"
                placeholder="e.g. Maintenance costs"
                value={roiStore.tempInputField}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => roiStore.tempInputField = e.target.value}
              />
              <button
                className="button--small flex align-center justify-center w-110"
                onClick={() => roiStore.addInputField()}
              >
                Add row
              </button>
            </div>

            <Gate condition={roiStore.inputFields.length > 0}>
              <div className="flex align-start justify-start">
                {roiStore.inputFields.map((field, i) => (
                  <button
                    key={i}
                    className="button--small flex flex-wrap align-center justify-between p-0p5 mt-0p5 mr-0p5"
                    onClick={() => roiStore.inputFields.splice(i, 1)}
                  >
                    {field}
                    <Icon className="hw-18 ml-0p5" name="close" />
                  </button>
                ))}
              </div>
            </Gate>
          </Gate>
        </div>

        <Gate condition={roiStore.stepOneCompleted}>
          <h4 className="px-1p5 mt-1 w-600">Fixed costs per year</h4>
          {roiStore.inputFields.length > 0
            ? (
              <div className="flex align-center justify-center full-width px-3 mt-1">
                <table>
                  <tr>
                    <th>
                      <b>Year</b>
                    </th>
                    {roiStore.yearsArray.map(year => (
                      <th key={year}>
                        <b>Year {year}</b>
                      </th>
                    ))}
                  </tr>
                  {roiStore.inputFields.map(field => (
                    <tr key={field}>
                      <td>
                        <b>{field}</b>
                      </td>
                      {roiStore.yearsArray.map(year => (
                        <td key={field + year}>
                          <input
                            value={roiStore.getInput(year, field)}
                            placeholder="$"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              roiStore.setInput(year, field, handleNumberInput(e));
                            }}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                  <tr>
                    <td>
                      <b>Total costs</b>
                    </td>
                    {roiStore.fixedCostsPerYear.map((cost, i) => (
                      <td key={i}>${format(cost)}</td>
                    ))}
                  </tr>
                </table>
              </div>
            )
            : (
              <div className="flex-down align-start justify-start full-width px-1p5 mt-1 w-600">
                <p>Add at least one cost row above to display costs table.</p>
              </div>
            )
          }

          <div className="flex-down align-start justify-start full-width p-1p5 mb-1 w-600">
            <h2 className="mt-2p5">Results</h2>

            {/** Per head */}
            <h4 className="mt-2">Per head Differences</h4>
            <table className="mt-1">
              <tr>
                <th className="empty" />
                <th>
                  <b>Cost ($)</b>
                </th>
                <th>
                  <b>Time (Seconds)</b>
                </th>
              </tr>
              <tr>
                <td>As Is</td>
                <td>{format(roiStore.metrics.as_is.costInDollars)}</td>
                <td>{format(roiStore.metrics.as_is.timeInSeconds)}</td>
              </tr>
              <tr>
                <td>To Be</td>
                <td>{format(roiStore.metrics.to_be.costInDollars)}</td>
                <td>{format(roiStore.metrics.to_be.timeInSeconds)}</td>
              </tr>
              <tr>
                <td>
                  <b>Cost and Time Savings (Benefits)</b>
                </td>
                <td>{format(benefits.cost)}</td>
                <td>{format(benefits.time)}</td>
              </tr>
              <tr>
                <td>
                  <b>Additional Costs and Time</b>
                </td>
                <td>{format(costs.cost)}</td>
                <td>{format(costs.time)}</td>
              </tr>
            </table>

            {/** Yearly Variable Costs */}
            <h4 className="mt-2">Yearly Variable Costs</h4>
            <table className="mt-1">
              <tr>
                <th className="empty" />
                <th>
                  <b>Per Year</b>
                </th>
              </tr>
              <tr>
                <td>Total per head additional cost</td>
                <td>{format(roiStore.perHeadAdditionalCost)}</td>
              </tr>
              <tr>
                <td>Total per head additional time in $</td>
                <td>{format(roiStore.perHeadAdditionalTimeCost)}</td>
              </tr>
              <tr>
                <td>
                  <b>Total Variable Additional Costs</b>
                </td>
                <td>{format(roiStore.totalVariableAdditionalCost)}</td>
              </tr>
            </table>

            {/** Yearly Benefits */}
            <h4 className="mt-2">Yearly Benefits</h4>
            <table className="mt-1">
              <tr>
                <th className="empty" />
                <th>
                  <b>Per Year</b>
                </th>
              </tr>
              <tr>
                <td>Total expected price increases</td>
                <td>{format(roiStore.expectedPriceIncreases)}</td>
              </tr>
              <tr>
                <td>Total expected labour cost savings</td>
                <td>{format(roiStore.expectedLabourCostSavings)}</td>
              </tr>
              <tr>
                <td>Total per head cost savings</td>
                <td>{format(roiStore.perHeadCostSavings)}</td>
              </tr>
              <tr>
                <td>Total per head time savings in $</td>
                <td>{format(roiStore.perHeadTimeCostSavings)}</td>
              </tr>
              <tr>
                <td>
                  <b>Total Benefits</b>
                </td>
                <td>{format(roiStore.totalBenefits)}</td>
              </tr>
            </table>
          </div>

          {/** Delta */}
          <h4 className="px-1p5 mt-1 w-600">&nbsp;</h4>
          <div className="flex align-center justify-center full-width px-3 mt-1">
            <table>
              <tr>
                <th className="empty" />
                {roiStore.yearsArray.map(year => (
                  <th key={year}>
                    <b>Year {year}</b>
                  </th>
                ))}
              </tr>
              <tr>
                <td>
                  <b>Net inflow</b>
                </td>
                {roiStore.netInflowPerYear.map((inflow, i) => <td key={i}>{format(inflow)}</td>)}
              </tr>
              <tr>
                <td>
                  <b>Net inflow per kilogram</b>
                </td>
                {roiStore.netInflowPerKiloPerYear.map((inflow, i) => <td key={i}>{format(inflow, 4)}</td>)}
              </tr>
            </table>
          </div>

          {/** NPV */}
          <div className="flex-down align-start justify-start full-width p-1p5 mb-1 w-600">
            <h4 className="mt-2">NPV</h4>
            <div className="flex-down align-start justify-start full-width mt-2">
              <div className="flex align-start justify-between full-width">
                <b>NPV</b>
                <p>${format(roiStore.calculateNpv(roiStore.netInflowPerYear))}</p>
              </div>
              <hr className="my-1" />
              <div className="flex align-start justify-between full-width">
                <b>PV (Fixed Costs)</b>
                <p>${format(roiStore.calculateNpv(roiStore.fixedCostsPerYear))}</p>
              </div>
              <hr className="my-1" />
              <div className="flex align-start justify-between full-width">
                <b>PV (Variable Costs)</b>
                <p>${format(roiStore.calculateNpv(roiStore.variableCostsPerYear))}</p>
              </div>
              <hr className="my-1" />
              <div className="flex align-start justify-between full-width">
                <b>PV (Benefits)</b>
                <p>${format(roiStore.calculateNpv(roiStore.benefitsPerYear))}</p>
              </div>
              <hr className="my-1" />
              <div className="flex align-start justify-between full-width">
                <b>Benefit Cost Ratio</b>
                <p>
                  {roiStore.ratioBenefitsToCosts
                    ? <>{format(roiStore.ratioBenefitsToCosts)}</>
                    : 'N/A'
                  }
                </p>
              </div>
            </div>
          </div>
        </Gate>
      </main>
    );
  }
}

