export const removeLettersFromEnd = (text: string, numOfletters: number): string => text.slice(0, -(numOfletters));

export const titleCase = (text: string): string => {
  let textArray = text.toLowerCase().split(' ');
  for (let i = 0; i < textArray.length; i++) {
    textArray[i] = textArray[i].charAt(0).toUpperCase() + textArray[i].slice(1);
  }
  return textArray.join(' ');
};

export const uppercaseFirstLetter = (text: string): string => {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}

export const formatCommunicationType = (type: string): string => {
  switch (type) {
    case 'email':
      return 'Email';

    case 'sms':
      return 'SMS';

    default:
      return '';
  }
}

export const formatPlural = (value: string, text: string) => parseInt(value, 10) !== 1
  ? text
  : text.substr(0, text.length - 1);
