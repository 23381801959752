import * as React from 'react';
import { IFormFieldProps } from '../../interfaces';
import { fieldHasErrors, formErrors, getPlaceholder, showTopLabel } from '../../utils';
import { Icon } from '../shared';

export const TextInput = (props: IFormFieldProps) => {
  const labelTop: boolean = showTopLabel(props);

  return (
    <>
      <label className={`text-input flex align-center justify-between ${props.className ? props.className : ''}`}>
        <input
          className={labelTop ? 'dropped' : ''}
          type={props.type}
          value={props.value}
          placeholder={getPlaceholder(props)}
          onBlur={props.onBlur}
          onChange={props.onChange}
          onFocus={props.onFocus}
          disabled={props.disabled}
        />
        {props.label && (
          <span className={`dynamic-label${labelTop ? '--top' : ''}`}>
            {labelTop
              ? <>{props.label}</>
              : <>{props.placeholder || props.label}</>
            }
            {props.tooltip !== undefined && <Icon className="hw-22" name="info-circle" />}
          </span>
        )}
        {props.icon && <Icon className="hw-32" name={props.icon} />}
      </label>
      {fieldHasErrors(props) && formErrors(props)}
    </>
  );
}
