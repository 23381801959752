import * as React from 'react';
import { preventEvents } from '../../utils';

interface IProps {
  buttonClassName?: string;
  buttonContent: JSX.Element|string;
  content: JSX.Element;
  type: string;
  preAction?: () => void;
}

interface IState {
  mounted: boolean;
  showContent: boolean;
}

export class ContentTrigger extends React.Component<IProps> {
  state: IState = {
    mounted: false,
    showContent: false,
  };

  componentDidMount() {
    // Register a click event to close the modal on outside click
    this.setState(
      { mounted: true },
      () => document.body.addEventListener('click', (e: Event) => this.eventClickListener(e)),
    );
  }

  componentWillUnmount() {
    if (this.state.mounted) {
      document.body.removeEventListener('click', (e: Event) => this.eventClickListener(e));
    }
  }

  eventClickListener(e: Event) {
    const { mounted, showContent, type } = {...this.state, ...this.props};

    if (mounted && showContent) {
      const target: any = e.target;

      switch (type) {
        case 'dropdown':
          const dropdown = document.body.getElementsByClassName('dropdown')[0];
          if (dropdown) {
            const clickedDropdownTrigger: boolean = target.classList.contains('dropdown-trigger');
            clickedDropdownTrigger && preventEvents(e);
            if (clickedDropdownTrigger || !dropdown.contains(target)) {
              this.toggleContent();
              return;
            }
          }
          // Execute action and close dropdown
          setTimeout(() => this.toggleContent(), 1);
          break;

        case 'modal':
          const modal = document.body.getElementsByClassName('backdrop')[0];
          if (modal && target.contains(modal)) {
            this.toggleContent();
          }
          break;

        default:
          break;
      }
    }
  }

  toggleContent(): void {
    const { mounted, showContent, type } = {...this.state, ...this.props};

    if (mounted) {
      this.setState(
        { showContent: !showContent },
        () => {
          // Toggle scrolling if modal
          if (type === 'modal') {
            document.body.style.overflow = this.state.showContent
              ? 'hidden'
              : 'initial';
          }
        }
      );
    }
  }

  get renderContent(): JSX.Element {
    const { content, type } = this.props;

    switch (type) {
      case 'modal':
        return React.cloneElement(content, { close: this.toggleContent.bind(this) });

      default:
        return content;
    }
  }

  render() {
    const { buttonClassName, buttonContent, preAction } = this.props;

    return (
      <React.Fragment>
        <button
          className={buttonClassName}
          onClick={() => {
            preAction !== undefined && preAction();
            this.toggleContent();
          }}
        >
          {buttonContent}
        </button>
        {this.state.showContent && this.renderContent}
      </React.Fragment>
    );
  }
}
