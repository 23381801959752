import * as React from 'react';
import { routerStore } from '../../stores';
import { session } from '../../services';

export class Root extends React.Component {
  componentDidMount() {
    routerStore.redirect(
      session.authenticated
        ? '/projects'
        : '/login'
      );
  }

  render() {
    return null;
  }
}
