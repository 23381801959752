import { IUser } from '../interfaces';
import { http } from './http.service';

interface IRegister extends IUser {
  application_key: string;
}

interface ILogin {
  email: string;
  password: string;
}

class AuthService {
  register(instance: RecursivePartial<IRegister>) {
    return http.post('/auth/register', instance);
  }

  login(instance: RecursivePartial<ILogin>) {
    return http.post('/auth/login', instance);
  }

  logout() {
    return http.get('/auth/logout');
  }

  me() {
    return http.get('/auth/me');
  }
}

export const authService = new AuthService();
