import { action, observable } from 'mobx';
import { Project, Model, Process } from '../classes';
import { ProjectActions, IProjectActionObject, ModelTypes, IProject, IProjectDisplay } from '../interfaces';
import { getIdentifierForAction } from '../utils';
import { routerStore } from './router.store';

class ProjectStore {
  @observable action: IProjectActionObject | null = null;
  @observable project?: Project;
  @observable projects: IProjectDisplay[] = [];

  @action
  setProject(project: IProject): void {
    this.project = new Project(project);
    routerStore.redirect('/project');
  }

  @action
  getModelForType(modelType: ModelTypes): Model|null {
    if (this.project) {
      return modelType === ModelTypes.AS_IS
        ? this.project.as_is
        : this.project.to_be;
    }
    return null;
  }

  // @TODO Move to utils?
  createActionObject(actionType: ProjectActions, model: Model): void {
    const currentProcess = (model.currentProcess as Process);
    const prefilledData: object = {
      ...(actionType === ProjectActions.UPDATE
        ? {
          name: currentProcess.name,
          description: currentProcess.description,
          notes: currentProcess.notes,
          costs: currentProcess.costs,
          data: currentProcess.data,
          time: currentProcess.time,
        }
        : {
          name: '',
          description: '',
          notes: '',
          costs: [],
          data: [],
          time: [],
        }
      ),
    };
    this.action = ({
      actionType,
      modelType: model.type,
      identifier: getIdentifierForAction(actionType, model.currentProcess),
      ...prefilledData,
    } as IProjectActionObject);
    routerStore.redirect('/project/action');
  }
}

export const projectStore = new ProjectStore();
