/**
 * Interfaces
 */
export interface IProject {
  id: string;
  name: string;
  as_is: IModel;
  to_be: IModel;
  roi: IRoiPersist | null;
  created_at: string;
}

export interface IProjectDisplay {
  id: string;
  name: string;
  created_at: string;
}

export interface IModel {
  id: string;
  name: string;
  type: ModelTypes;
  processes: IProcess[];
}

export interface IKeyProcess {
  [key: string]: IProcess;
}

export interface IProcess {
  id: string;
  model_id: string|null;
  parent_id: string|null;
  next_id: string|null;
  prev_id: string|null;
  next_sibling_id: string|null;
  prev_sibling_id: string|null;
  name: string;
  identifier?: string;
  description: string;
  notes: string;
  costs: string;
  data: string;
  time: string;
}

export interface IProcessCosts {
  item: string;
  cost: string;
}

export interface IProcessData {
  label: string;
  values: string[];
}

export interface IProcessTime {
  label: string;
  value: string;
  unit: ProcessTimeUnits;
}

export interface IProjectActionObject {
  actionType: ProjectActions;
  modelType: ModelTypes;
  identifier: string;
  name: string;
  description: string;
  notes: string;
  costs: IProcessCosts[];
  data: IProcessData[];
  time: IProcessTime[];
}

export interface IUser {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

export interface IUserAPIResponse {
  access_token: string;
  profile: IUser;
}

export interface IRoiPersist {
  id?: string;
  project_id: string;
  salary: string;
  interest_rate: string;
  weight: string;
  increasePerKilo: string;
  labourCostSavings: string;
  number_of_units: string;
  years: string;
  input_fields: string[];
  input_map: { key: string, value: string }[];
}

/**
 * Enums
 */
export enum ModelTypes {
  AS_IS = 'as_is',
  TO_BE = 'to_be',
}

export enum ProjectActions {
  NEW = 'new',
  AFTER = 'after',
  BEFORE = 'before',
  CHILD = 'child',
  UPDATE = 'update',
  DELETE = 'delete',
}

export enum SaveStates {
  NOT_SAVING = 'not saving',
  SAVING = 'saving',
  SAVED = 'saved',
}

export enum ProcessTimeUnits {
  SECONDS = 'seconds',
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
}
