import * as React from 'react';
import { observer } from 'mobx-react';
import { Model, Project } from '../../classes';
import { projectController } from '../../controllers';
import { ILabelVal, ProjectActions } from '../../interfaces';
import { projectStore } from '../../stores';
import { formatPlural } from '../../utils';
import { SelectInput } from '../form';
import { Button } from './button.component';
import { ContentTrigger } from './content-trigger.component';
import { Icon } from './icon.component';
import { ProcessData } from './process-data.component';

interface IProps {
  name: string;
  model: Model;
}

@observer
export class ModelWrapper extends React.Component<IProps> {
  get processOptions(): ILabelVal[] {
    const { model } = this.props;
    const options: ILabelVal[] = [];
    let process = model.rootProcess;

    if (!process) { return []; } // If no root process exists, return

    // Sort processess from root to last
    while (process) {
      options.push({
        label: process.displayName,
        value: process.id,
      });

      process = process.hasNextProcess
        ? model.processes[(process.next_id as string)]
        : null;
    }

    return options;
  }

  render() {
    const { model } = this.props;
    const { currentProcess: process } = model;

    return (
      <div className="model-wrapper flex-1 flex-down align-start justify-start full-width p-1p5 pt-4p5">
        <h1>{model.name} Model</h1>
        {process && (
          <div className="flex align-start justify-start full-width mt-1">
            <Button
              className="btn-change-process--first"
              disabled={!process.hasPreviousProcess}
              onClick={() => model.currentProcessId = process.prev_id}
            >
              <Icon name="chevron-left" />
            </Button>
            <SelectInput
              label="Process"
              icon="chevron-down"
              value={process.id}
              options={this.processOptions}
              setValue={(key: string, id: string) => model.currentProcessId = id}
              padProcesses
            />
            <Button
              className="btn-change-process--last"
              disabled={!process.hasNextProcess}
              onClick={() => model.currentProcessId = process.next_id}
            >
              <Icon name="chevron-right" />
            </Button>
          </div>
        )}
        <div className="flex align-start justify-between full-width mt-2">
          {process
            ? (
              <div className="flex-down align-start justify-start full-width">
                <div className="flex align-start justify-between full-width">
                  <span className="title">{process.displayName}</span>
                  <div className="dropdown-wrapper">
                    <ContentTrigger
                      type="dropdown"
                      buttonClassName="dropdown-trigger button--medium flex align-center justify-between w-154 ml-3"
                      buttonContent={
                        <>
                          <p>Process action</p>
                          <Icon
                            className="hw-22 ml-0p25"
                            name="chevron-down"
                          />
                        </>
                      }
                      content={
                        <div className="dropdown dropdown--medium w-200">
                          {model.currentProcessId && (
                            <>
                              {[ProjectActions.BEFORE, ProjectActions.AFTER, ProjectActions.CHILD].map(action => (
                                <button
                                  key={action}
                                  onClick={() => projectStore.createActionObject(action, model)}
                                >
                                  Insert {action}
                                </button>
                              ))}
                              <button onClick={() => projectStore.createActionObject(ProjectActions.UPDATE, model)}>
                                Update
                              </button>
                              {(Object.keys(model.processes).length > 1 && process && process.isDeletable) && (
                                <button
                                  onClick={() => {
                                    if (confirm('Are you sure you want to delete this process?')) {
                                      const processes = model.deleteCurrentProcess();
                                      projectController.deleteProcesses((projectStore.project as Project), processes);
                                    }
                                  }}
                                >
                                  Delete
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      }
                    />
                  </div>
                </div>
                <h4 className="mt-2">Description</h4>
                <p className="mt-0p5">
                  {process.description
                    ? process.description
                    : 'No description.'
                  }
                </p>
                <h4 className="mt-2">Notes</h4>
                {process.notes
                  ? (
                    <textarea
                      className="textarea mt-0p5"
                      value={process.notes}
                      readOnly
                    />
                  )
                  : <p className="mt-0p5">No notes.</p>
                }
              </div>
            )
            : <h3>No processes</h3>
          }
        </div>

        {/* Costs */}
        {(process && process.costs.length > 0) && (
          <div className="flex-down align-start justify-start full-width mt-2">
            <h4 className="mb-0p5">Costs</h4>
            {process.costs.map((el, i) => (
              <div
                key={i}
                className="flex align-center justify-between full-width border-bottom py-0p5"
              >
                <b>{el.item}</b>
                <p>${el.cost}</p>
              </div>
            ))}
            {/* @TODO Re-enable to show sum */}
            {/* {process.costs.length > 1 && (
              <b
                className="mt-0p5"
                style={{ marginLeft: 'auto' }}
              >
                = ${calculateCostsSum(process.costs)}
              </b>
            )} */}
          </div>
        )}

        {/* Time */}
        {(process && process.time.length > 0) && (
          <div className="flex-down align-start justify-start full-width mt-2">
            <h4 className="mb-0p5">Time</h4>
            {process.time.map((el, i) => (
              <div
                key={i}
                className="flex align-center justify-between full-width border-bottom py-0p5"
              >
                <b>{el.label}</b>
                <p>{el.value} {formatPlural(el.value, el.unit)}</p>
              </div>
            ))}
          </div>
        )}

        {/* Process data */}
        {process && process.data.map(data => (
          <ProcessData
            key={data.label}
            {...data}
          />
        ))}
      </div>
    );
  }
}
