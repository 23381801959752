import { Project } from '../classes';
import { downloadFileBlob } from '../utils';
import { http } from './http.service';
import { IRoiPersist } from '../interfaces';

interface INewProject {
  name: string;
  type: 'copy' | 'scratch' | 'template';
}

class ProjectService {
  root: string = '/projects';

  index() {
    return http.get(this.root).then(res => res.data);
  }

  delete(id: string) {
    return http.delete(`${this.root}/${id}`);
  }

  show(id: string) {
    return http.get(`${this.root}/${id}`).then(res => res.data);
  }

  create(newProject: RecursivePartial<INewProject>) {
    return http.post(this.root, newProject).then(res => res.data);
  }

  update(project: Project) {
    return http.patch(`${this.root}/${project.id}`, project);
  }

  deleteProcesses(id: string, processes: string[]) {
    return http.post(`${this.root}/${id}/delete-processes`, { processes });
  }

  spreadsheet(project: Project) {
    return http
      .get(`${this.root}/${project.id}/spreadsheet`)
      .then(res => res.blob())
      .then(blob => downloadFileBlob(blob, project.name));
  }

  setRoi(project: Project, roi: IRoiPersist) {
    return http.post(`${this.root}/${project.id}/roi`, roi);
  }
}

export const projectService = new ProjectService();
