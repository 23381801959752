import * as React from 'react';
import { Link } from 'react-router-dom';
import * as dateFns from 'date-fns';
import { observer } from 'mobx-react';
import { projectService } from '../../services';
import { projectStore as store, routerStore, projectStore } from '../../stores';
import { Icon } from '../../components';

interface IState {
  loading: boolean;
}

@observer
export class UserProjects extends React.Component {
  state: IState = { loading: true };

  componentDidMount() {
    projectService
      .index()
      .then(projects => { store.projects = projects })
      .catch(err => console.log(err))
      .then(() => this.setState({ loading: false }));
  }

  getProject(id: string) {
    projectService
      .show(id)
      .then(project => store.setProject(project))
      .catch(err => console.log(err));
  }

  render() {
    const { loading } = this.state;
    const { projects } = projectStore;

    return (
      <main className="flex-down align-start justify-start h-100vh">
        <div className="navbar flex align-center justify-center full-width p-1p5">
          <button
            className="left-wrapper flex align-center justify-center"
            onClick={() => routerStore.logout()}
          >
            <Icon
              className="hw-24"
              name="chevron-left"
            />
            <p>Log out</p>
          </button>
          <h4>Projects</h4>
          <Link className="btn-create-project" to="/new-project">
            New project
          </Link>
        </div>
        <hr />
        <div className="flex flex-1 align-start justify-center full-width p-1p5">
          {loading && <p>Loading...</p>}
          {!loading && (
            <div className="flex-down align-start justify-start w-600">
              {projects.length === 0 && <p>No projects.</p>}
              {projects.map((project, i) => (
                <button
                  key={i}
                  className={`button flex align-center justify-between text-align-left full-width ${i !== 0 ? 'mt-1' : ''}`}
                  onClick={() => this.getProject(project.id)}
                >
                  <b>{project.name}</b>
                  <p>{dateFns.format(new Date(project.created_at), 'DD/MM/YY')}</p>
                </button>
              ))}
            </div>
          )}
        </div>
      </main>
    );
  }
}
