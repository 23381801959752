import * as dateFns from 'date-fns';
import { observable } from 'mobx';
import { v4 as uuid } from 'uuid';
import { IProject, ModelTypes, IRoiPersist } from '../interfaces';
import { Model, newModel } from './Model';
import { Process } from './Process';

export class Project {
  id: string;
  @observable name: string;
  created_at: string;
  @observable as_is: Model;
  @observable to_be: Model;
  @observable roi: IRoiPersist | null;

  constructor(project: IProject) {
    this.id = project.id;
    this.name = project.name;
    this.created_at = project.created_at;
    this.as_is = new Model(project.as_is);
    this.to_be = new Model(project.to_be);
    this.roi = project.roi;
  }

  /**
   * Add identifier to each process.
   *
   * @return {Project}
   */
  get formattedForAPI(): Project {
    const projectCopy: Project = JSON.parse( JSON.stringify( this ) );
    [ModelTypes.AS_IS, ModelTypes.TO_BE].forEach(modelType => {
      const { processes } = this[modelType];
      Object.keys(processes).map(key => {
        const process: Process = processes[key];
        projectCopy[modelType].processes[key] = {
          ...process,
          identifier: process.identifier,
        } as Process;
      });
    });
    return projectCopy;
  }
}

export const newProject = (name: string) => {
  return {
    id: uuid(),
    name,
    created_at: dateFns.format(new Date()),
    as_is: newModel('As Is', ModelTypes.AS_IS),
    to_be: newModel('To Be', ModelTypes.TO_BE),
    roi: null,
  };
}
