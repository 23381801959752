import { History } from 'history';
import { action, observable} from 'mobx';
import { CONFIG } from '../config';
import { session, authService } from '../services';

class RouterStore {
  @observable loggedIn: boolean = session.has('access_token');
  history: History = CONFIG.history;

  @action
  redirect(uri: string) {
    this.history.push(uri);
  }

  @action
  login() {
    this.loggedIn = true;
    this.history.push(CONFIG.loginPath);
  }

  @action
  logout() {
    this.loggedIn = false;
    authService
      .logout()
      .then(() => {
        session.destroy(CONFIG.sessionKey);
        this.history.push(CONFIG.logoutPath);
      });
  }
}

export const routerStore = new RouterStore();
