import * as React from 'react';
import { routerStore } from '../../stores';

export const NotFound = () => (
  <main className="main flex-down align-center justify-center h-100vh">
    <h1>404</h1>
    <button
      className="mt-1"
      onClick={() => routerStore.redirect('/')}
    >
      Click here to go back
    </button>
  </main>
);
