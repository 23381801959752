import { IRoute } from '../interfaces';
import * as Views from '../views/shared';

export const sharedRoutes: IRoute[] = [
  {
    path: '/',
    component: Views.Root,
    exact: true,
  },
  {
    path: '/login',
    component: Views.Login,
    exact: true,
  },
  {
    path: '/register',
    component: Views.Register,
    exact: true,
  },
];
