import * as React from 'react';
import { IFormFieldProps } from '../../interfaces';
import { Icon } from '../shared';
import { fieldHasErrors, formErrors, getPlaceholder, showTopLabel } from '../../utils';

export const TextAreaInput = (props: IFormFieldProps) => (
  <>
    <label className={`textarea-input flex align-center justify-between ${props.className ? props.className : ''}`}>
      <textarea
        className={showTopLabel(props) ? 'dropped' : ''}
        value={props.value}
        placeholder={getPlaceholder(props)}
        onBlur={props.onBlur}
        onChange={props.onChange}
        onFocus={props.onFocus}
        readOnly={props.readOnly}
        rows={10}
      />
      {props.label && <span className={`dynamic-label${showTopLabel(props) ? '--top' : ''}`}>{props.label}</span>}
      {props.icon && <Icon className="hw-32" name={props.icon} />}
    </label>
    {fieldHasErrors(props) && formErrors(props)}
  </>
);
