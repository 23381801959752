import * as React from 'react'

interface IProps {
  children: React.ReactNode | React.ReactNode[];
  condition: boolean
}

export const Gate = (props: IProps) => {
  const { children, condition } = props
  return condition
    ? <>{children}</>
    : null
}
