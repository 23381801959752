import { IRoute } from '../interfaces';
import { session } from '../services';
import * as Views from '../views/user';

const guard: (() => boolean) = () => session.authenticated;

export const userRoutes: IRoute[] = [
  {
    path: '/new-project',
    component: Views.UserNewProject,
    exact: true,
    guard,
  },
  {
    path: '/roi',
    component: Views.UserROI,
    exact: true,
    guard,
  },
  {
    path: '/project',
    component: Views.UserProject,
    exact: true,
    guard,
  },
  {
    path: '/project/action',
    component: Views.UserProjectAction,
    exact: true,
    guard,
  },
  {
    path: '/projects',
    component: Views.UserProjects,
    exact: true,
    guard,
  },
];
