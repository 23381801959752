import * as React from 'react';
import { observer } from 'mobx-react'
import { Project } from '../../classes';
import { Button } from './button.component';
import { Icon } from './icon.component';

type Direction = 'next' | 'prev';

interface IProps {
  project: Project;
}

export const DualNavigation = observer(({ project }: IProps) => {
  const canNavigate = (direction: Direction): boolean => {
    const {
      as_is: { currentProcess: asIsProcess },
      to_be: { currentProcess: toBeProcess }
    } = project;

    if (!asIsProcess || !toBeProcess) { return false; }

    switch (direction) {
      case 'next':
        return asIsProcess.hasNextProcess && toBeProcess.hasNextProcess;

      case 'prev':
        return asIsProcess.hasPreviousProcess && toBeProcess.hasPreviousProcess;

      default:
        return false;
    }
  }

  const navigate = (direction: Direction): void => {
    if (canNavigate(direction) && project.as_is.currentProcess && project.to_be.currentProcess) {
      switch (direction) {
        case 'next':
          project.as_is.currentProcessId = project.as_is.currentProcess.next_id;
          project.to_be.currentProcessId = project.to_be.currentProcess.next_id;
          break;

        case 'prev':
          project.as_is.currentProcessId = project.as_is.currentProcess.prev_id;
          project.to_be.currentProcessId = project.to_be.currentProcess.prev_id;
          break;
      }
    }
  }

  return (
    <div className="dual-navigation-wrapper flex align-center justify-center">
      <Button
        className="btn-change-process"
        disabled={!canNavigate('prev')}
        onClick={() => navigate('prev')}
      >
        <Icon name="chevron-left" />
      </Button>
      <Button
        className="btn-change-process"
        disabled={!canNavigate('next')}
        onClick={() => navigate('next')}
      >
        <Icon name="chevron-right" />
      </Button>
    </div>
  );
})
