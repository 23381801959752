import { action, computed, observable } from 'mobx';
import { v4 as uuid } from 'uuid';
import { IModel, ModelTypes, IProjectActionObject, ProjectActions, IProcess } from '../interfaces';
import { getLeafProcessForTree } from '../utils';
import { KeyProcess, Process, newProcess } from './Process';

export class Model {
  id: string;
  @observable name: string;
  @observable type: ModelTypes;
  @observable processes: KeyProcess = {};
  @observable currentProcessId: string|null;

  constructor(model: IModel) {
    this.id = model.id;
    this.name = model.name;
    this.type = model.type;
    for (const process of (model.processes as IProcess[])) {
      this.insertOrUpdateProcess(
        new Process(process, this)
      );
    }
    // Set currentProcessId to first process, or null if it no processes exist on the model
    const processKeyArray: string[] = Object.keys(this.processes);
    this.currentProcessId = processKeyArray.length > 0
      ? this.processes[processKeyArray[0]].id
      : null;
  }

  @computed
  get rootProcess(): Process|null {
    for (const key in this.processes) {
      const process = this.processes[key];
      if (process.prev_id === null) {
        return process;
      }
    }

    return null;
  }

  @computed
  get currentProcess(): Process|null {
    return this.currentProcessId && this.currentProcessId in this.processes
      ? this.processes[this.currentProcessId]
      : null;
  }

  @action
  insertOrUpdateProcess(process: Process) {
    this.processes[process.id] = process;
  }

  @action
  createOrUpdateProcessForAction(action: IProjectActionObject): void {
    const {
      actionType,
      name,
      description,
      notes,
      costs,
      data,
      time,
    } = action;

    // Get current process
    const currentProcess: Process = this.processes[(this.currentProcessId as string)];

    // If editing currentProcess, update and return
    // @TODO Move to switch statement?
    if (actionType === ProjectActions.UPDATE) {
      currentProcess.name = name;
      currentProcess.description = description;
      currentProcess.notes = notes;
      currentProcess.costs = costs;
      currentProcess.data = data;
      currentProcess.time = time;
      return;
    }

    const process = newProcess(this, name, description, notes, costs, data, time, currentProcess.parent_id);

    switch (action.actionType) {
      case ProjectActions.AFTER:
        // If the current process has a leaf process, chain the next process to the leaf
        const leafProcess = getLeafProcessForTree(currentProcess, this);
        if (leafProcess) {
          // If the leaf has a next process, attach it to the new process
          if (leafProcess.hasNextProcess) {
            const leafNextProcess = this.processes[(leafProcess.next_id as string)];
            process.next_id = leafNextProcess.id
            leafNextProcess.prev_id = process.id;
          }
          leafProcess.next_id = process.id;
          process.prev_id = leafProcess.id;
        } else if (currentProcess.hasNextProcess) {
          const nextProcess = this.processes[(currentProcess.next_id as string)];
          nextProcess.prev_id = process.id;
          process.next_id = nextProcess.id;
        }

        // If there's no leaf process, bind new process to current process
        if (!leafProcess) {
          currentProcess.next_id = process.id;
          process.prev_id = currentProcess.id;
        }

        // Re-bind sibling_ids
        process.prev_sibling_id = currentProcess.id;
        process.next_sibling_id = currentProcess.next_sibling_id;
        currentProcess.next_sibling_id = process.id;
        if (process.next_sibling_id) {
          this.processes[process.next_sibling_id].prev_sibling_id = process.id;
        }

        break;

      case ProjectActions.BEFORE:
        // Bind to previous process
        if (currentProcess.hasPreviousProcess) {
          const prevProcess = (currentProcess.prevProcess as Process);
          prevProcess.next_id = process.id;
          process.prev_id = prevProcess.id;
        }

        // Bind to current process
        currentProcess.prev_id = process.id;
        process.next_id = currentProcess.id;

        // Re-bind sibling_ids
        process.next_sibling_id = currentProcess.id;
        process.prev_sibling_id = currentProcess.prev_sibling_id;
        currentProcess.prev_sibling_id = process.id;
        if (process.prev_sibling_id) {
          this.processes[process.prev_sibling_id].next_sibling_id = process.id;
        }

        break;

      case ProjectActions.CHILD:
        process.parent_id = currentProcess.id;
        const { lastChild } = currentProcess;
        if (lastChild) {
          if (lastChild.hasNextProcess) {
            const nextProcess = this.processes[(lastChild.next_id as string)];
            // Bind prev/next ids
            nextProcess.prev_id = process.id;
            process.next_id = nextProcess.id;
          }

          // Bind prev/next ids
          lastChild.next_id = process.id;
          process.prev_id = lastChild.id;

          // Bind sibling ids
          lastChild.next_sibling_id = process.id;
          process.prev_sibling_id = lastChild.id;
        } else {
          if (currentProcess.hasNextProcess) {
            const nextProcess = this.processes[(currentProcess.next_id as string)];
            // Bind nextProcess and process
            nextProcess.prev_id = process.id;
            process.next_id = nextProcess.id;
          }

          // Bind process and currentProcess
          currentProcess.next_id = process.id;
          process.prev_id = currentProcess.id;
        }

        break;

      default:
        return;
    }

    this.insertOrUpdateProcess(process);
  }

  @action
  deleteCurrentProcess(): string[] {
    const currentProcess = (this.currentProcess as Process);
    const deletedProcesses = currentProcess.deleteChildren();
    const deletedProcess = currentProcess.delete();
    deletedProcesses.push(currentProcess.id);
    this.currentProcessId = deletedProcess.prev_id || deletedProcess.next_id;
    return deletedProcesses;
  }
}

export const newModel = (name: string, type: ModelTypes): IModel => {
  const model_id: string = uuid();

  return {
    id: model_id,
    name,
    type,
    processes: [
      {
        id: uuid(),
        model_id,
        parent_id: null,
        next_id: null,
        prev_id: null,
        next_sibling_id: null,
        prev_sibling_id: null,
        name: 'Process',
        description: '',
        notes: '',
        costs: JSON.stringify([]),
        data: JSON.stringify([]),
        time: JSON.stringify([]),
      },
    ],
  };
}
