import { observable, action } from 'mobx';
import { Project } from '../classes';
import { SaveStates } from '../interfaces';
import { projectService } from '../services';

class AutosaveStore {
  autosaveMs: number = 20000;
  @observable timeoutId: number = 0;
  @observable saveState: SaveStates = SaveStates.NOT_SAVING;
  @observable project?: Project;

  @action
  initiate(project: Project) {
    this.project = project;
  }

  @action
  save() {
    if (this.project) {
      this.saveState = SaveStates.SAVING;
      projectService
        .update(this.project.formattedForAPI)
        .then(() => {
          setTimeout(() => {
            this.saveState = SaveStates.SAVED;
          }, 1500);
          setTimeout(() => {
            this.saveState = SaveStates.NOT_SAVING;
          }, 4500);
        })
        .catch(err => console.log(err));
    }
  }
}

export const autosaveStore = new AutosaveStore();
